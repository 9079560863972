import React from 'react'
import { Link } from 'react-router-dom'
import CdnImg from '../CdnImg'
import Nav from 'react-bootstrap/Nav'
import SocialMediaIcons from '../../../molecules/SocialMediaIcons'
import { Desktop, Mobile } from '../../../atoms/responsive'
import { currentYear } from '../../../../constants/constants'
import { useSiteContext } from '../../../../context/site/SiteProvider'

const ProLineFooter: React.FC = () => {
  const { storeSettings, appSettings } = useSiteContext()
  return (
    <>
      <footer className="dm-proline-primary-bg p-3">
        <Mobile>
          <SocialMediaIcons className="d-flex align-items-center justify-content-center text-center pb-3" />
        </Mobile>
        <div className="d-flex">
          <div
            className="d-flex flex-column me-auto"
            style={{ maxWidth: '25%' }}
          >
            <div className="img-fluid me-auto">
              <CdnImg
                path="_images/dm-pro-line-header-logo.png"
                alt="logo_new_white"
                width="125"
              />
            </div>
            <div className="text-white mt-1" style={{ fontSize: '9px' }}>
              Disclaimer: Professional Use Only products can only be purchased
              with proof of training. Professional Use products should not be
              sold in stores.
            </div>
          </div>
          <Desktop>
            <SocialMediaIcons className="mx-auto" />
          </Desktop>
          <div className="ms-auto pe-2 text-white text-end">
            {appSettings?.aws_public_files_path &&
            storeSettings?.privacy_policy_filename ? (
              <Nav className=" justify-content-end">
                <Nav.Item className="pe-1">
                  <Link
                    to={`//76fireworks.com/prolinenews`}
                    className="text-white"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Subscribe to our Newsletter
                  </Link>
                </Nav.Item>
                <Nav.Item className="pe-1"> · </Nav.Item>
                <Nav.Item className="pe-1">
                  <Link to="/contact" className="text-white">
                    Contact Us
                  </Link>
                </Nav.Item>
                <Nav.Item className="pe-1"> · </Nav.Item>
                <Nav.Item className="pe-1">
                  <a
                    href={
                      appSettings.aws_public_files_path +
                      storeSettings.terms_of_use_filename
                    }
                    target="_blank"
                    className="text-white"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>
                </Nav.Item>
                <Nav.Item className="pe-1"> · </Nav.Item>
                <Nav.Item>
                  <a
                    href={
                      appSettings.aws_public_files_path +
                      storeSettings.privacy_policy_filename
                    }
                    target="_blank"
                    className="text-white"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </Nav.Item>
              </Nav>
            ) : (
              <></>
            )}
            <div className="mb-0 mt-1 text-light">
              <small>
                &copy; {currentYear} '76 ProLine By Spirit of '76 LLC
              </small>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default ProLineFooter
